/* eslint-disable react/no-multi-comp */
import {Dispatch, SetStateAction, useState} from "react";
import MyDataTable from "../../../components/common/MyDataTable";
import {extractDateFromTimestamp} from "../../../utils/DateUtils";
import {AppointmentFilters, AppointmentRow} from "./types";
import {SelectedDateType} from "../../../components/common/MyCalendar/types";
import {SEARCH_DEBOUNCE_TIME} from "../../../constants/Values";
import {useSelector} from "react-redux";
import {selectUser} from "../../../redux/slice/authSlice";
import useDebounce from "../../../hooks/utils/debounce/useDebounce";
import {DataTableFilters, SelectedPeriod} from "../../../components/common/MyDataTable/types";
import {getAppointmentRows, getColumns, getFilters} from "./dataUtils";
import {useGetAppointmentListQuery} from "../../../hooks/appointment/query";
import {PAGE_SIZE_OPTIONS} from "../../../components/common/MyDataTable/constants";
import {Appointment} from "../../../api/appointment/appointment.interface";
import {Box} from "@mui/material";
import {useNavigate} from "react-router-dom";

const Appointments = () => {
  const [appointmentFilters, setAppointmentFilters] = useState<AppointmentFilters>({
    selectedPeriod: SelectedPeriod.TODAY,
    selectedDateRange: {modified: false, range: {}} as SelectedDateType,
    searchText: "",
    appointment_date_start: extractDateFromTimestamp(new Date().toISOString(), "yyyy/mm/dd", "-"),
    appointment_date_end: extractDateFromTimestamp(new Date().toISOString(), "yyyy/mm/dd", "-"),
    selectedFilters: {},
    limit: PAGE_SIZE_OPTIONS[0],
    offset: 0,
  });
  const debouncedSearchText = useDebounce(appointmentFilters.searchText, SEARCH_DEBOUNCE_TIME);
  const emr_name = useSelector(selectUser)?.emr_system_details?.emr_name;
  const appointmentsColumns = getColumns(emr_name!);
  const filters = getFilters(emr_name!);
  const {data} = useGetAppointmentListQuery({
    offset: appointmentFilters.offset,
    limit: appointmentFilters.limit,
    order_by_desc: false,
    appointment_date_start: appointmentFilters.appointment_date_start,
    appointment_date_end: appointmentFilters.appointment_date_end,
    search_name_or_mrn: debouncedSearchText,
    ...appointmentFilters.selectedFilters,
  })!;
  const navigate = useNavigate();

  const rows = (getAppointmentRows(data?.results ?? [], emr_name!, navigate) as any) ?? [];
  const onUpdate = (dateStart: any, dateEnd: any, pageSize: number) => {
    setAppointmentFilters(prev => {
      return {
        ...prev,
        appointment_date_start: dateStart,
        appointment_date_end: dateEnd,
        limit: pageSize,
      };
    });
  };
  return (
    <Box>
      <MyDataTable<Appointment, AppointmentRow[]>
        rowCount={data?.count}
        onUpdate={onUpdate}
        rows={rows}
        columns={appointmentsColumns}
        data={data?.results ?? []}
        dataTableFilters={appointmentFilters}
        setDataTableFilters={setAppointmentFilters as Dispatch<SetStateAction<DataTableFilters>>}
        filters={filters}
        initialState={{
          showFilterIcon: true,
          showPeriodSelect: true,
          showSearchBar: true,
        }}
      />
    </Box>
  );
};

export default Appointments;
