import {AppointmentType} from "../../shared/Global.types";

interface Patient {
  id: number;
  identifier?: string;
  first_name?: string;
  last_name?: string;
  age?: number;
  sex_at_birth?: string;
  medical_record_number?: string;
}

export enum APPOINTMENT_STATUS {
  UPCOMING = "scheduled",
  CANCELLED = "cancelled",
  IN_PROGRESS = "in_progress",
  COMPLETED = "completed",
  MISSED = "missed",
  NONE = "none",
}

export interface Appointment {
  id: number;
  identifier: string;
  appointment_name: string;
  appointment_time: string;
  appointment_start_time: string;
  appointment_type: string;
  doctor: number;
  patient?: Patient;
  reason_for_visit: string;
  metadata: Record<string, string>;
  appointment_status?: APPOINTMENT_STATUS;
}

export interface GetAppointmentListParams {
  appointment_date_end?: string;
  appointment_date_start?: string;
  appointment_type?: AppointmentType;
  limit?: number;
  offset?: number;
  order_by_desc?: boolean;
  search?: string;
  search_name_or_mrn?: string;
  status?: APPOINTMENT_STATUS;
  patient_id?: string;
}

export interface AppointmentIdPaylod {
  appointment_id: number;
}
